import React from 'react'
import {Offcanvas} from 'react-bootstrap'
import {FontAwesomeIcon as Fa} from '@fortawesome/react-fontawesome'
import {ToggleSwitch} from './ToggleSwitch'
import {Flags} from './App'
import {UserType} from './caltypes'
import {is_admin, is_auth} from './utilrole'
//
// OffMenu  Offcanvasで設定メニューとする
//

//
// flex box
//
const container={
  display: "flex",
  //flex-direction: "row", //「メイン軸方向」は横、がデフォ。
  alignItems: "flex-end", //「クロス軸方向」の配置、下端そろえ。
  borderBottom: "1px dotted darkblue",
  marginBottom: "0.4rem"
}
// flex item 右寄せする部分
const row_right={
  marginLeft: "auto"
}
// クロス軸を特定itemで変更
const cross_center={
  alignSelf: "center"
}
//
// このoffcanvas自体の show/hide はHeader からの制御
// flags は App-->Header-->OffMenuともらい、設定するもの。(App配下で使うので)
type Props = {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  flags: Flags;
  setFlags: React.Dispatch<React.SetStateAction<Flags>>;
  user: UserType;
}
export const OffMenu = ({show, setShow, flags, setFlags, user}: Props): JSX.Element => {

  const LBug= () => (  <Fa icon={['fas', 'bug']} style={cross_center} className="fa-lg me-1"  />  )

  const setfLogio = (f:boolean) : void => setFlags({...flags, fLogio:f})
//  const setfUserInfo = (f:boolean) : void => setFlags({...flags, fUserInfo:f})
//  const setfTesicon = (f:boolean) : void => setFlags({...flags, fTesicon:f})
//  const setfTesio = (f:boolean) : void => setFlags({...flags, fTesio:f})
  const setfChangePW = (f:boolean) : void => setFlags({...flags, fChangePW:f})
  const setfLogin = (f:boolean) : void => setFlags({...flags, fLogin:f})
//  const setfUpFile = (f:boolean) : void => setFlags({...flags, fUpFile:f})
//  const setfDnFile = (f:boolean) : void => setFlags({...flags, fDnFile:f})
  const setfAdminTool = (f:boolean) : void => setFlags({...flags, fAdminTool:f})
  const setfMailTo = (f:boolean) : void => setFlags({...flags, fMailTo:f})
  //const setfLogout = (f:boolean) : void => setFlags({...flags, fLogout:f})
  const setfCalendar = (f:boolean) : void => setFlags({...flags, fCalendar:f})
  const setfReport = (f:boolean) : void => setFlags({...flags, fReport:f})
  const setfDebug = (f:boolean) : void => setFlags({...flags, fDebug:f})
  const setfDebug2 = (f:boolean) : void => setFlags({...flags, fDebug2:f})
//  const setfAdminEdit = (f:boolean) : void => setFlags({...flags, fAdminEdit:f})
  const setfEditUserFull = (f:boolean) : void => setFlags({...flags, fEditUserFull:f})
  //const setfAddUserFull = (f:boolean) : void => setFlags({...flags, fAddUserFull:f})
  return(
  <Offcanvas show={show} onHide={()=>setShow(false)} >
    <Offcanvas.Header closeButton>
      <Offcanvas.Title>設定</Offcanvas.Title>
    </Offcanvas.Header>
    <Offcanvas.Body>

    {is_auth(user) &&
    <div style={container} >
      <div>パスワードを変更する</div>
      <div style={row_right}>
        <ToggleSwitch flag={flags.fChangePW} setFlag={setfChangePW}/>
      </div>
    </div>
    }

    <div style={container} >
      <div>パスワード再発行依頼</div>
      <div style={row_right}>
        <ToggleSwitch flag={flags.fMailTo} setFlag={setfMailTo} />
      </div>
    </div>

    {is_admin(user.username) &&
    <div style={container}>
      <LBug />
      <div>ユーザー編集と追加</div>
      <div style={row_right}>
        <ToggleSwitch flag={flags.fEditUserFull} setFlag={setfEditUserFull} />
      </div>
    </div>
    }
    
    {/* {is_admin(user.username) &&
    <div style={container}>
      <LBug />
      <div>ユーザー新規追加</div>
      <div style={row_right}>
        <ToggleSwitch flag={flags.fAddUserFull} setFlag={setfAddUserFull} />
      </div>
    </div>
    } */}

    {is_admin(user.username) &&
    <div style={container}>
      <LBug />
      <div>予約一覧ダウンロード</div>
      <div style={row_right}>
        <ToggleSwitch flag={flags.fReport} setFlag={setfReport} />
      </div>
    </div>
    }

    {is_admin(user.username) &&
    <div style={container} >
    <LBug />
      <div>ログイン</div>
      <div style={row_right}>
        <ToggleSwitch flag={flags.fLogin} setFlag={setfLogin}/>
      </div>
    </div>
    }
  
    {/* Logout は Headerの アイコンからトグルなのでここでは switchを表示しないことにする。*/}
  
    {is_admin(user.username) &&
    <div style={container} >
    <LBug />
      <div>Calendar</div>
      <div style={row_right}>
        <ToggleSwitch flag={flags.fCalendar} setFlag={setfCalendar} />
      </div>
    </div>
    }

    {/* {is_admin(user.username) &&
    <div style={container} >
      <LBug />
      <div >Logio</div>
      <div style={row_right}>
        <ToggleSwitch flag={flags.fLogio} setFlag={setfLogio}/>
      </div>
    </div>
    } */}

    {/* {is_admin(user.username) &&
    <div style={container} >
    <LBug />
      <div>AdminTool</div>
      <div style={row_right}>
        <ToggleSwitch flag={flags.fAdminTool} setFlag={setfAdminTool} />
      </div>
    </div>
    } */}

    {/* {is_admin(user.username) &&
    <div style={container} >
    <LBug />
      <div>UserInfo</div>
      <div style={row_right}>
        <ToggleSwitch flag={flags.fUserInfo} setFlag={setfUserInfo}/>
      </div>
    </div>
    } */}

    {/* { is_admin(user.username) &&
    <div style={container} >
    <LBug />
      <div>Tesicon</div>
      <div style={row_right}>
        <ToggleSwitch flag={flags.fTesicon} setFlag={setfTesicon}/>
      </div>
    </div>
    } */}

    {/* {is_admin(user.username) &&
    <div style={container} >
    <LBug />
      <div>Tesio</div>
      <div style={row_right}>
        <ToggleSwitch flag={flags.fTesio} setFlag={setfTesio}/>
      </div>
    </div>
    } */}

    {/* {is_admin(user.username) &&
    <div style={container} >
    <LBug />
      <div>UpFile</div>
      <div style={row_right}>
        <ToggleSwitch flag={flags.fUpFile} setFlag={setfUpFile} />
      </div>
    </div>
    } */}

    {/* {is_admin(user.username) &&
    <div style={container} >
    <LBug />
      <div>DnFile</div>
      <div style={row_right}>
        <ToggleSwitch flag={flags.fDnFile} setFlag={setfDnFile} />
      </div>
    </div>
    } */}

    {/* デバッグスイッチ */}
    {/* {is_admin(user.username) &&
    <div style={container}>
      <LBug />
      <div>Debug</div>
      <div style={row_right}>
        <ToggleSwitch flag={flags.fDebug} setFlag={setfDebug} />
      </div>
    </div>
    } */}

    {/* デバッグスイッチ2 */}
    {/* {is_admin(user.username) &&
    <div style={container}>
      <LBug />
      <div>Debug2</div>
      <div style={row_right}>
        <ToggleSwitch flag={flags.fDebug2} setFlag={setfDebug2} />
      </div>
    </div>
    } */}

    {/* {is_admin(user.username) &&
    <div style={container}>
      <LBug />
      <div>Admin Edit</div>
      <div style={row_right}>
        <ToggleSwitch flag={flags.fAdminEdit} setFlag={setfAdminEdit} />
      </div>
    </div>
    } */}


    </Offcanvas.Body>
  </Offcanvas>
  )
}
