import {format} from 'date-fns'
import path from 'path'
import {UserType, userNone} from './caltypes'
import { Line } from './utilline'
//import {FontAwesomeIcon as Fa} from '@fortawesome/react-fontawesome'


// inline styles
export const bg_ivory = {backgroundColor: "ivory"}
export const bg_snow = {backgroundColor: "snow"}
export const bg_beige = {backgroundColor: "beige"}
export const bg_lightgoldenrodyellow = {backgroundColor: "lightgoldenrodyellow"}

export function desktopDir(){
  const platform= process.platform // win32
  const key = platform === 'win32' ? 'USERPROFILE' : 'HOME'
  const home = process.env[key] || './' //あとでちゃんと ...
  return path.join(home, 'Desktop') // C:/Users/sasa/Desktop
}
export function downloadsDir(){
  const platform= process.platform // win32
  const key = platform === 'win32' ? 'USERPROFILE' : 'HOME'
  const home = process.env[key] || './' 
  return path.join(home, 'Downloads') // C:/Users/sasa/Downloads
}

export const stamp = () :string => format(new Date(), 'yyyy-MMdd HH:mm:ss')
export const hms = () :string => format(new Date(), 'HH:mm:ss')
export const hmsS = () :string => format(new Date(), 'HH:mm:ss.SSS')

// new Date() されて serverから来たJSONの... ??ちょっと注意も必要
// date-fnsの breaking-changesとして https://date-fns.org/v2.25.0/docs/parseISO  あとで。
export const dt_hms = (dt: Date) : string => format(dt, 'HH:mm:ss')
export const dt_hmsS = (dt: Date) : string => format(dt, 'HH:mm:ss.SSS')
export const dt_stamp = (dt: Date) : string => format(dt, 'yyyy-MMdd HH:mm:ss')

// socket経由できた dt --> isoString をそのまま stackしておいて、localstorageにするので、表示はこの stamp_isoで。
export const dts_none = '1970-01-01T00:00:00Z'
export const dts_now = () => (new Date()).toISOString()
export const dts_stamp = (dts: string) => (dts && 0<dts.length)
    ? format( new Date(dts),  'yyyy-MMdd HH:mm:ss')
    : dts_none


//ざっくり
export function stringify2(o: object){
  return JSON.stringify(o).replace(/"/g, '')
}

//
// User詳細のために
//
export function get_user(name: string, users: UserType[]): UserType {
  const found: UserType | undefined = users.find( u=> u.username === name )
  const user= found ? found : userNone
  return {...user}
}
//
type Info1 = {
  tag: string;
  line: Line;
  user: UserType;
}
export function info1( {tag, line, user }: Info1 ) : string {
  const row= line.row
  const u= user
  //
  // @2022-1027 予約（法要の）連絡先として row.memo  欄をつかった。 see parts/PhoneOrMail
  //
  const msg_base=`\
------------------------------------------
予約日: ${row.date}
種別: ${row.kind}
予約: ${line.empty ? `予約がありません` : `すでに予約が存在します`}
予約者: ${u.username} ${u.kname} ${u.mail} ${u.addr1}
状態: ${line.enable ? `予約できます` : `予約できません`}
制約: ${line.reason}
(link): ${line.row.link}
(option): ${line.option}
(id): ${line.row.id < 0 ? 'なし' : line.row.id}
`
  //
  //
  //
  const msg_sogi=`\
------------------------------------------
葬儀詳細
葬儀(家の名前): ${row.funeral_owner}
通夜: ${row.funeral_tuya}
葬儀開始時間: ${row.funeral_start}
葬儀終了時間: ${row.funeral_end}
宗派: ${row.funeral_denomination}
`
  //
  //
  //
  const msg_hoyo=`\
------------------------------------------
法要詳細
年忌法要: ${row.hoyo_nenki}
その他: ${row.hoyo_misc}
法要場所: ${row.hoyo_at}
法要(施主名): ${row.hoyo_owner}
法要(施主電話番号): ${row.hoyo_phone}
法要(住所など備考): ${row.hoyo_addr}
予約連絡先: ${row.memo}
`
  //
  // 「詳細」ボタンから windows ダイアログにて 情報を表示するため、など。
  //
  const text= tag.startsWith('hoyo') ? (msg_base + msg_hoyo) : (msg_base + msg_sogi)
  //
  return text
}

//
// local storage を利用した log utility
// Logio で表示
// kqから emit('notify', {dt, msg}) で来る。Logioではこれを socket.onして捕まえる。
// storageには key '@logcount@' 名にて 件数を書き込んでおく。
// storageには key 'log001' 名にて 文字列(msg)を書き込んでおく。
//
export type NotifyMsg = {
  dts: string; //Date;--> new Date().toISOstring() されてくるのでそのまま保持。
  msg: string;
}
const NotifyKey = '@notifies@'
//
// ● NotifyMsg [] を書いてみる
//
export function notify({dts, msg}: NotifyMsg){
  const notifies = get_notifies()
  notifies.push({dts, msg})
  const json= JSON.stringify(notifies)
  localStorage.setItem(NotifyKey, json)
}
export function get_notifies(): NotifyMsg[]{
  const value= localStorage.getItem(NotifyKey)
  let obj= []
  if( value !== null){
    obj= JSON.parse(value)
  }
  const notifies: NotifyMsg[] = obj as NotifyMsg[]
  return notifies
}
//
// ●remove_
//
export function remove_notifies(){
  localStorage.removeItem(NotifyKey) // removeItem() は void である。
}
//