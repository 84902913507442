//
// cal types
//
// kq/kr で共通部分の多い型や constをまとめる。
import {Line} from './utilline.js' // @2022-1009 ConstraintDayが utillineとの接点となった
//
// ●予約レコードの型
//
export interface ReservationRow {
  id: number;
  date: string;
  facility: string; //金蓮寺会館
  kind: string;
  user: string;
  start: number;
  end: number;
  link: string;
  memo: string; //備考欄自由形式が必要なら。
  funeral_tuya: string; //あれば、その開始時刻を入力なければ空を。
  funeral_owner: string; //家の名前
  funeral_start: string; //葬儀開始時刻
  funeral_end: string; //葬儀終了時刻
  funeral_denomination: string; //宗派 (What Buddhism denomination do you belong to?)
  hoyo_nenki: string; // 四十九日、一周忌、３回忌、七回忌　十三回忌　十七回忌　などをご記入ください 
  hoyo_misc: string; //墓閉まい、仏壇開眼などをご記入ください
  hoyo_at: string; //自宅/金蓮寺/その他 の３種
  hoyo_owner: string; //施主名を入力してもらう
  hoyo_addr: string; //お参りする場所
  hoyo_phone: string; //施主電話番号
}
export const reservationNone = {
  id: -1,
  date: '1970-01-01T00:00:00Z',
  facility: '',
  kind: '',
  user: '',
  start: 0,
  end: 0,
  link: '',
  memo: '',
  funeral_tuya: '',
  funeral_owner: '',
  funeral_start: '',
  funeral_end: '',
  funeral_denomination: '',
  hoyo_nenki: '',
  hoyo_misc: '',
  hoyo_at: '',
  hoyo_owner: '',
  hoyo_addr: '',
  hoyo_phone: ''
}
export interface LogRow {
  id: number;
  at: string;
  log: string;
}
//
// ●user type
//
// サーバーで照合するために持つユーザー情報は以下のとおりとする
// 認証したあと done()にて cookieに落とす内容は idとusernameだけ。
// mail-notifyを SMTP発信するにあたり (adminにはすくなくとも) mailアドレスが
// フィールドとして必要なので、変更した。
export type UserType = {
  id: number;
  username: string;
  password: string;
  note: string;
  danka: boolean;
  funeral: boolean;
  mail: string;
  role: string;
  phone: string;
  kname: string;
  addr1: string;
  addr2: string;
}
export const userNone: UserType = {
  id:-1, username: '', password: '', note: '', danka: false, funeral: false, 
  mail: '', role: '', phone: '', kname: '', addr1: '', addr2: ''
}
//
//
// _CalType --> ●CalType するために... 日付部分が全部Date型になっている
// 
export type ReservationDay = {
  d: Date; //文字列ではなくなっている
  rs: ReservationRow[];
}

type ReservationWeek = ReservationDay []

export type CalType = {
  weeks: ReservationWeek[];
  begin: Date;
  end: Date;
  start: Date;
  last: Date;
  endDay: ReservationDay;
}
//
// ●constraint day 制約ずみ Line[]をCalendarをもとに別途処理して２か月分保存する@2022-1009
//
export type ConstraintDay = {
  dt: Date;
  lines: Line[];
}
export const constraintDayNone = {
  dt: new Date('1970-01-01T00:00:00Z'),
  lines: [] 
}


//
// ●itemD クリックされた日の予約情報を calendar-->dayformへ渡すため。
//
export type ItemDType = {
 touched: boolean;
 i: number; //ここは ? でなくてもいいか。
 j: number;
 o: ReservationDay; //@@@o?-->o
}

//
// ●reservationDayNone
//
export const reservationDayNone = {
  d: new Date('1970-01-01T00:00:00Z'),
  rs: []
}
//
// ●itemDNone
//
export const itemDNone = {
  touched: false,
  i: -1, j: -1,
  o: reservationDayNone
}

