//
// UserInput
//
import React from "react"
import {UserType} from '../caltypes'
import {FarEye, FarEyeSlash, Radio, Btn, Checkbox, InputGrp,
  InputTextLabel, Text, TextArea,
} from './Misc'


//
// Email type emailの入力
//
const Email = ({id, value, label, readOnly, onChange}: {
  id: string;
  value: string;
  label: string;
  readOnly: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}) => (

  <div className="form-floating mb-1">
    <input id={id}
      type="email" className="form-control"
      value={value} onChange={onChange}
      placeholder="@"
      readOnly={readOnly}
    />
    <label htmlFor={id}>{label}</label>
  </div>

)
//
// Password 入力 type passwordと text を切り替える処理がつく
//
const Password = ({id, value, label, onChange}: {
  id: string;
  value: string;
  label: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}) => {
  const [isRevealPassword, setIsRevealPassword] = React.useState(false)

  const togglePassword= ()=>{
    setIsRevealPassword( (prevState) => !prevState)
  }

  return(
    <div className="form-floating mb-1">
      <input
        id={id}
        type={isRevealPassword ? 'text': 'password'}
        className="form-control"
        onChange={onChange} 
        value={value}
        placeholder="@"
      />
      <label htmlFor={id}>{label}</label>
      <span
        className="password-reveal pointer"
        onClick={togglePassword}
      >
      {isRevealPassword
        ? <FarEye />
        : <FarEyeSlash />
        }
      </span>
    </div>)
}
//
// ●UserInput  (編集では既存のデータをもらうが password は空ではじめる)
//
export const UserInput= ({user, mode, cb_upd, cb_del, cb_add}:{
  user: UserType;
  mode: string;
  cb_upd: (u: UserType) => void;
  cb_del: (u: UserType) => void;
  cb_add: (u: UserType) => void;
}): JSX.Element => {
  const df_kind= (mode: string, d: boolean, f: boolean) =>{// d ? '檀家様' : ( f ? '業者様' : '' )
    if(mode==='新規') return '檀家様'
    else if(d) return '檀家様'
    else if(f) return '業者様'
    else return ''
  }
  //
  const [id, setId] = React.useState(user.id)
  const [username, setUsername] = React.useState(user.username)
  const [password, setPassword] = React.useState('')
  const [note, setNote] = React.useState(user.note)
  const [ukind, setUkind] = React.useState(df_kind(mode, user.danka, user.funeral))
  const [mail, setMail] = React.useState(user.mail)
  const [kname, setKname] = React.useState(user.kname)
  const [phone, setPhone] = React.useState(user.phone)
  const [addr1, setAddr1] = React.useState(user.addr1)
  const [addr2, setAddr2] = React.useState(user.addr2)
  // パスワード変更を表示するか否か
  const [changePassword, setChangePassword]= React.useState<boolean>(user.id<0 ? true: false)

  const upd= () => {
    const u: UserType = {
      id, username, password, note, role: '',
      danka: ukind === '檀家様',
      funeral: ukind === '業者様',
      mail, kname, phone, addr1, addr2
    }
    cb_upd(u)
  }
  const del= () => {
    const u: UserType = {
      id, username, password, note, role: '',
      danka: ukind === '檀家様',
      funeral: ukind === '業者様',
      mail, kname, phone, addr1, addr2
    }
    cb_del(u)
  }
  const add= () => {
    const u: UserType = {
      id, username, password, note, role: '',
      danka: ukind === '檀家様',
      funeral: ukind === '業者様',
      mail, kname, phone, addr1, addr2
    }
    cb_add(u)
  }
 
  //
  // render
  //
  return(<>
  
  { mode==='編集' &&
  <InputGrp>
    <Btn onClick={upd} disabled={false}>更新</Btn>
    <Text>{user.username} {user.kname} </Text>
    <Btn onClick={del} disabled={false}>削除</Btn>
  </InputGrp>
  }
  { mode==='新規' &&
  <InputGrp>
    <Btn onClick={add} disabled={false}>登録</Btn>
    <Text>{user.username} {user.kname} </Text>
  </InputGrp>
  }


    {/* floatingさせないといけないので groupを組まない */}
    <Email
      id="edit-user-full-email"
      label="Username" 
      value={username}
      readOnly={mode==='新規' ? false: true}
      onChange={e => setUsername(e.target.value)} />
    
    <Checkbox disabled={user.id<0 ? true: false}
      checked={changePassword} onChange={()=>setChangePassword(prev => !prev)}
      label={0<user.id ? 'パスワードを変更する' : 'パスワードを入力する'} />
    
    { changePassword &&
    <Password
      id="edit-user-full-password" 
      label="Password" 
      value={password}
      onChange={e => setPassword(e.target.value)} />
    }

    <InputGrp>
      <Radio value={'檀家様'} checked={ukind==='檀家様'}
        onChange={e => setUkind(e.target.value)}  />

      <Radio value={'業者様'} checked={ukind==='業者様'}
        onChange={e => setUkind(e.target.value)}  />
    </InputGrp>

    <InputGrp>
      <InputTextLabel
        label='mail'
        value={mail}  placeholder="abc@exmple.com"
        onChange={e => setMail(e.target.value)} />
    </InputGrp>

    <InputGrp>
      <InputTextLabel
        label='電話'
        value={phone} placeholder=""
        onChange={e => setPhone(e.target.value)} />
    </InputGrp>

    <InputGrp>
      <InputTextLabel
        label='漢字名'
        value={kname} placeholder=""
        onChange={e => setKname(e.target.value)} />
    </InputGrp>

    <TextArea
      label={'住所1'}
      value={addr1}
      onChange={e => setAddr1(e.target.value)} />

    <TextArea 
      label={'住所2'}
      value={addr2}
      onChange={e => setAddr2(e.target.value)} />

    <TextArea
      label={'備考'}
      value={note}
      onChange={e => setNote(e.target.value)}/>

  </>)
}
