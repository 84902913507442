//
// EditUserFull (管理者用ユーザー編集画面) @2022-1111
//
import React from "react"
import {Context, Flags} from './App'
import {UserType, userNone} from './caltypes'
import {UserInput} from './parts/UserInput'
import {SearchUser} from './parts/SearchUser'
import {
  FasTimesCircle, Radio, Btn, Text, InputGrp
} from './parts/Misc'
import { is_admin } from "./utilrole"
//
// ●EditUserFull
//
export const EditUserFull= ({login, flags, setFlags}:{
  login: UserType; // ログインユーザー
  flags: Flags;
  setFlags: React.Dispatch<React.SetStateAction<Flags>>;
}): JSX.Element => {
  const {socket} = React.useContext(Context)
  const [mode, setMode] = React.useState<string>('検索')
  const [user, setUser] = React.useState<UserType>(userNone)

  //
  // socket on/off subscribes
  //
  React.useEffect( ()=>{
    socket.on('edit-user-full', ({success, msg}:{success: boolean; msg: string;})=>{
      console.log(`@edit-user-full ${msg}`)
    })
    socket.on('notify', ({dt, msg}:{dt:Date; msg:string;}) =>{
      console.log(`@notify ${msg}`)
    })
    return ()=>{
      socket.off('edit-user-full')
      socket.off('notify')
    }
  }, [socket])

  //
  // flag off (この画面が非表示となる)
  //
  const close = () => {
    setFlags({...flags, fEditUserFull: false})
  }
  //
  // callback
  //
  const cb_search = (u: UserType) => {
    console.log(`@@@ search ${u.id} ${u.username}`)
    setUser(u)
    setMode('編集')
  }

  //
  // 実行 emit 発行
  //
  const emit_del_user = (u: UserType)=>{
    const arg= {username: u.username }
    console.log('@emit del-user', u)
    socket.emit('del-user', arg)
  }
  const emit_edit_user_full = (u: UserType)=>{
    console.log('@emit edit-user-full', u)
    // passwordが空なら変更しない、のでここではチェックは不要、でいいかな。
    socket.emit('edit-user-full', u)
  }
  const emit_add_user= (u: UserType)=>{
    console.log('@emit add-user', u)
    socket.emit('add-user', u)
  }

  // check
  if( !is_admin( login.username )){
    return(
      <div style={{border: "1px solid red"}} >
      ユーザー編集機能 は 特権ユーザー専用です。<br/>
      (ログインしなおしてください)
      </div>
    )
  }

  //
  // render
  //
  return(<>

    <InputGrp>
      <Text>ユーザー情報の更新と新規追加</Text>
      <Btn onClick={close}><FasTimesCircle /></Btn>
    </InputGrp>

    <InputGrp>
      <Radio
        value="検索" checked={mode==='検索'} onChange={()=> {setMode('検索'); setUser(userNone)}} />

      <Radio
        value="編集" checked={mode==='編集'} onChange={()=> setMode('編集')} disabled={user.id<0} />

      <Radio
        value="新規" checked={mode==='新規'} onChange={()=> {setMode('新規'); setUser(userNone)}} />

    </InputGrp>

    { mode==='検索' &&<SearchUser cb={cb_search} /> }

    { mode==='編集' && <UserInput mode={mode} user={user}
      cb_upd={emit_edit_user_full} cb_del={emit_del_user} cb_add={()=>{}} />}

    { mode==='新規' && <UserInput mode={mode} user={user}
      cb_upd={()=>{}} cb_del={()=>{}} cb_add={emit_add_user} />}


  </>)
}
