//
// このファイルを index.tsx で 
// import './fawesome'
//  としておくことで、個々のコンポーネントでは
// ---
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// <FontAwesomeIcon icon={['fas', 'user-nurse']}
// --->のようにして icon として使えるようになる。
// (もしincludeしなければつまりGlobalねlibraryがつかえなければiocnデータが空、でsyntaxとしてエラーにならない)
// (わかりにくいのは faCoffee というシンボルをadd したら ['fas', 'coffee'] として使えるということ。)
//

/*
 使うときの注意
 React, Typescriptで Fa アイコンをつかうとき、部品化したいときなどはちょっと型に注意だ。
 ['fas', 'user'] を渡しているのだがこれは string[2]ではないから。
 

 import {IconLookup} from '@fortawesome/fontawesome-svg-core'
 ...で型 IconLookup を得て、（ここにはこの版で使える prefixとnameがすべて定義された型なのだ）
   const iconClear: IconLookup = { prefix: 'fas', iconName: 'times-circle'}
... のように定義して、propsに渡して
  const SomeParts ({icon}: 型) {
    ... 
  <Fa icon={icon} className="fa-lg" />
    のように propsから得て、あてる。
  }

  type SomeParts = {
    ...
    icon: IconLookup; という型で受けなければ、部品で使えない。
    ...
  }

  ということでたいていの場合は モジュール冒頭で使うものを簡単に定義して以下のように使うのが便利
  (そもそもここ fontawesome.tsxで library化して まとめて必要なものを選択してあるのでこれでいいと思う)

  const FasUser = () => <Fa icon={['fas', 'user']} className="fa-lg" />

  ...

  <button >
    <FasUser />
  </button>

  
 */


//
// libraryを使ってたぶんiconデータをGlobalにつかえるようにする。
//
import {
  library
} from '@fortawesome/fontawesome-svg-core'


//
// たぶんデータではなくシンボル名を得て、libraryに使う。(add)
//

//使い方はあってるはずなので typeの問題かな。
//https://fontawesome.com/docs/web/use-with/react/add-icons 
// icondefinitionorpack icondefinition

/* ???error
C:/p/K3/kr/src/fawesome.tsx
TypeScript error in C:/p/K3/kr/src/fawesome.tsx(64,3):
Argument of type 'IconDefinition' is not assignable to parameter of type 'IconDefinitionOrPack'.
  Type 'IconDefinition' is not assignable to type 'IconPack'.
    Index signature for type 'string' is missing in type 'IconDefinition'.  TS2345

    62 | //
    63 | library.add(
  > 64 |   fasLeaf,
       |   ^
    65 |   faApple, faMicrosoft, faGoogle,
    66 |   faBug, faList, faListAlt,
    67 |   faUser, faUserCircle, faUserTimes, faUserCheck, faUserCog,


*/

// FA6になってすこし調整して freeでまとめるように faUsersClassつまり複数の人、faFileUserがなかったので代替をさがす。
// https://fontawesome.com/icons/ から。 fiilterは Users+People 
// faFile faFileLines

// ●solid

import {
  faBug, faList, faListAlt,
  faUser, faUserCircle, faUserTimes, faUserCheck, faUserCog,
  faUserPlus, faUserMinus,
  faUsers, faFileLines,
  //faUsersClass, faFileUser,
  faUserSlash,
  faEnvelope, faEnvelopeOpen,
  faBell, 
  faFileAlt, faFileDownload, faFileUpload,
  faBars, faTimesCircle,
  faChevronCircleUp, faChevronCircleDown,
  faLeaf,
  faInfoCircle,
  
} from '@fortawesome/free-solid-svg-icons'

import{
  faCircle, faCircleUser, faCircleXmark,
  faEye, faEyeSlash,
} from '@fortawesome/free-regular-svg-icons'

// ●brands
import {
  faApple, faMicrosoft, faGoogle
} from '@fortawesome/free-brands-svg-icons'
//これ↓だと全部がlibraryにはいってしまうので...注意。
//import { fab } from '@fortawesome/free-brands-svg-icons'
//import {fas}from '@fortawesome/free-solid-svg-icons'

// lightは proしかないので ここでは削除した。2022-0411
//●light
// import {
//   faBug, faList, faListAlt,
//   faUser, faUserCircle, faUserTimes, faUserCheck, faUserCog,
//   faUserPlus, faUserMinus, faUsersClass, faFileUser, faUserSlash,
//   faEnvelope, faEnvelopeOpen,
//   faBell, 
//   faFileAlt, faFileDownload, faFileUpload,
//   faBars, faTimesCircle,
//   faChevronCircleUp, faChevronCircleDown,
//   faLeaf,
//   faInfoCircle,
// } from '@fortawesome/pro-light-svg-icons'

//
// importの途中で library.add()などの関数実行を書いてはいけない。
//

// brands
library.add(  faApple, faMicrosoft, faGoogle )

// light
library.add(
  faBug, faList, faListAlt,
  faUser, faUserCircle, faUserTimes, faUserCheck, faUserCog,
  faUserPlus, faUserMinus, faUsers, faFileLines, faUserSlash,
  faEnvelope, faEnvelopeOpen,
  faBell, 
  faFileAlt, faFileDownload, faFileUpload,
  faBars, faTimesCircle,
  faChevronCircleUp, faChevronCircleDown,
  faLeaf,
  faInfoCircle,
  faCircle, faCircleUser, faCircleXmark,
  faEye, faEyeSlash
)

