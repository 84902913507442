import React from 'react'
import {FontAwesomeIcon as Fa} from '@fortawesome/react-fontawesome'
import {Context, Flags} from './App'
import {UserType} from './caltypes'
import {fetch_login, BodyLogin} from './fetchHelpers'
import {is_auth} from './utilrole'
import {Btn, BtnA} from './parts/Btn'
import {Email, Password} from './parts/Input'
//
// icons ここで使うもの
//
const FasUser = () => <Fa icon={['fas', 'user']} className="fa-lg" />
const FasTimesCircle = () => <Fa icon={['fas', 'times-circle']} className="fa-lg" />

//
// ●入力のエラー表示部品
//
const Err = ({msg}:{msg: string;}): JSX.Element => {
  let e = <></>
  // とりあえず省略する
  // if(msg && 0<msg.length){
  //   e= <div className="text-white bg-danger">{msg}</div>
  // }
  return e
}
//
// ●Login
//
export const Login = ({user, setUser, flags, setFlags}: {
  user:UserType;
  setUser: React.Dispatch<React.SetStateAction<UserType>>;
  flags: Flags;
  setFlags: React.Dispatch<React.SetStateAction<Flags>>;
  }) :JSX.Element => {
  const {socket, urls} = React.useContext(Context)
  const [msg, setMsg] = React.useState('')
  const [errU, setErrU] = React.useState('')
  const [errP, setErrP] = React.useState('')
  const [canLogin, setCanLogin] = React.useState(false)
  //
  const [username, setUsername] = React.useState('')
  const [password, setPassword] = React.useState('')
    //
  React.useEffect( ()=> {
    if(!is_auth(user)){
      setMsg('←ログインしてください')
    }else{
      setMsg(`(${user.username} ログイン中)`)
    }
    return ()=>{}
  }, [user, setMsg])
  //
  // ●toggle reveal
  //

  //
  //●ref read -->この Login.tsx では refものはなし、で作れることがわかったので。
  //
  // function cval(e: React.RefObject<HTMLInputElement>) : string {
  //   const s= e.current ? e.current.value : ''
  //   return s
  // }
  //
  // ●ref write
  //
  // function clear(e: React.RefObject<HTMLInputElement>){
  //   if(e.current) e.current.value= ''
  // }
  
  //
  // clear
  //
  // (ログインできていないなら 入力をclearする。 -->やめ、refをやめたので初期値はクリアされて始まる)
  // ログインできた、なら flagsを使って、表示をオフにする。
  //
  function clear(){
    setFlags({...flags, fLogin: false})
    // 
    // if( username === '' && password === ''){
    //   // already cleared, so cancel login.???
    //   setFlags({...flags, fLogin: false})
    // }else{
    //   setCanLogin(false)
    //   setMsg('ログインしてください')
    // }
  }
  //
  // 入力文字数をチェックする
  //
  function test(s: string, min: number, max: number): boolean {
    return ( min <= s.length && s.length <= max )
  }
  //
  // ●ユーザー名とパスワードを入力 
  //  長さをチェックする。
  //  両方がチェック通ればボタンを押せるようにする。のだがそのきっかけは「どちらかのonChange」なのでややこしい。
  //
  const onChangeUsername = (e: React.ChangeEvent<HTMLInputElement>)=>{
    //console.log('@@@', e.currentTarget.value)
    setUsername(e.currentTarget.value)
    const ok = test(e.currentTarget.value, 1, 32)
    if(!ok){
      setErrU( '3文字以上32文字以下に!' )
      setCanLogin(false)
      setMsg( '3文字以上32文字以下に!' )
      return
    }

    setErrU('')
    setMsg('')
    setCanLogin(true)
    setMsg('←ログインを押してしてください')
    if( test(password, 3, 32) ){
      setCanLogin(true)
      setMsg('←ログインを押してしてください')
    }
  }
  const onChangePassword = (e: React.ChangeEvent<HTMLInputElement>)=>{
    setPassword(e.currentTarget.value)
    const ok = test(e.currentTarget.value, 3, 32)
    if(!ok){
      setErrP( '3文字以上32文字以下に!' )
      setCanLogin(false)
      setMsg( '3文字以上32文字以下に!(password)' )
      return
    }
    setErrP('')
    setMsg('')
    if(test(username,3, 32)){
      setCanLogin(true)
      setMsg('←ログインを押してしてください')
    }
  }
  //
  // ●ログイン実行
  //
  const handleLogin = async () =>{
    const body: BodyLogin= {username, password, socket_id: socket.id}
    const url= `${urls.login}` // without notify
    const res = await fetch_login(url, body)
    if(res.success){
      setUser(res.user)
      setCanLogin(false)
      setMsg(`ログインが成功しました`)
      //自動で消していいか。
      setFlags({...flags, fLogin: false})
      }else{
      setMsg(`ユーザー名またはパスワードが違います`)
    }
  }
  //
  // Email, Password は どちらも input だけど Passwordは Eye/EyeSlashの切り替えをもつ。
  // BtnLogin は ログインのハンドルが非同期なので Btnと区別している。
  //
  return(<>
    
    <FasUser />{' '}ログイン:

    <Err msg={errU} />
    <Email
      id="login-email"
      handle={onChangeUsername}
      value={username}
      label="Username"
    />
    
    <Err msg={errP} />
    <Password
      id="login-password"
      handle={onChangePassword}
      value={password}
      label="Password"
    />
 
    {/* アイコンボタン メッセージ クリアボタン の input group*/}
    <div className="input-group mb-1">

      <BtnA handle={handleLogin} enable={canLogin}>
        {/* <FasUser /> */}
        ログイン
      </BtnA>

      <div className="input-group-text form-control">{msg}</div>

      <Btn handle={clear}>
        <FasTimesCircle />
      </Btn>
    
    </div>
  </>)
}
